import { Button, FormGroup, Label, Alert } from "reactstrap";
import React from "react";

import AuthBlock from "../../components/authBlock/AuthBlock";
import Authentication from "../../services/Authentication";
import ElsanLogo from "../../components/elsanLogo/ElsanLogo";
import Input from "../../components/input/Input";
import validationErrors from "../../services/ValidationErrors";
import ValidationForm from "../../components/validationForm/ValidationForm";

import AuthApi from "../../apis/AuthApi";

import "./LoginPage.scss";
import StringHelper from "../../helpers/StringHelper";
import ApiErrorsHelper from "../../helpers/ApiErrorsHelper";

export default class LoginPage extends React.PureComponent {
  state = {
    loginError: null,
    validationErrors: null,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    this.props.layoutContextProvider.change({ title: "Connexion" });
  }

  onSubmit = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    if (this.state.loginError) {
      this.setState({ loginError: null, validationErrors: null });
    }

    let response = await AuthApi.login(formData.email, formData.password).catch(
      (e) => {
        this.form.current.updateInputsWithError(
          ApiErrorsHelper.convertApiValidationError(e.raw)
        );

        this.setState({
          loginError: StringHelper.isNullOrEmpty(e.message)
            ? "L'utilisateur est introuvable."
            : e.message,
        });

        return null;
      }
    );

    if (response) {
      Authentication.login(response.token);
      this.props.history.push("/");
    }

    this.props.layoutContextProvider.hideLoader();
  };

  render() {
    return (
      <AuthBlock
        title="ADEL BO Support"
        subtitle="Espace Administrateur"
        logo={<ElsanLogo />}
      >
        <ValidationForm
          ref={this.form}
          onSubmit={this.onSubmit}
          onChange={this.onFormChange}
        >
          <div className="m-4">
            <FormGroup>
              <Label>Adresse email</Label>
              <Input
                id="emailInput"
                type="email"
                name="email"
                placeholder="Adresse email"
                validations="isEmail"
                validationError={validationErrors.isEMail}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>Mot de passe</Label>
              <Input
                id="passwordInput"
                type="password"
                name="password"
                placeholder="Mot de passe"
                required
              />
            </FormGroup>

            {!StringHelper.isNullOrEmpty(this.state.loginError) && (
              <Alert color="danger">{this.state.loginError}</Alert>
            )}
          </div>
          <Button color="secondary" block className="loginButton">
            Valider
          </Button>
        </ValidationForm>
      </AuthBlock>
    );
  }
}
