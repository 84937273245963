import { Route } from "react-router-dom";
import React from "react";

import "./PublicLayout.scss";
import { withLayoutContextProvider } from "../../services/LayoutContext";

class PublicLayout extends React.PureComponent {
  render() {
    const { component, layoutContextProvider, ...routeProps } = this.props;
    const RouteComponent = component;

    return (
      <div className="publicLayoutContent">
        <div className="container mainContainer">
          <Route
            {...routeProps}
            render={props =>
              RouteComponent ? (
                <RouteComponent
                  {...props}
                  layoutContextProvider={this.props.layoutContextProvider}
                />
              ) : null
            }
          />
        </div>
      </div>
    );
  }
}

export default withLayoutContextProvider(PublicLayout);
