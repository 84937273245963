import ApiClient from "../services/ApiClient";

export default class ClinicsApi {
    static getAll() {
        return ApiClient.get("clinics");
    }

    static add(data) {
        return ApiClient.post("clinics", data);
    }

    static update(id, data) {
        return ApiClient.patch(`clinics/${id}`, data);
    }

    static delete(id) {
        return ApiClient.delete(`clinics/${id}`);
    }
}