import React from "react";
import { PropTypes } from "prop-types";
import "./AuthBlock.scss";
import StringHelper from "../../helpers/StringHelper";

export default class AuthBlock extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string,
    logo: PropTypes.node,
    subtitle: PropTypes.string,
    children: PropTypes.node
  };

  render() {
    return (
      <div className="authBlock">
        <div className="text-center mt-4">
          {this.props.logo}
          {!StringHelper.isNullOrEmpty(this.props.title) && (
            <h1>{this.props.title}</h1>
          )}
          {!StringHelper.isNullOrEmpty(this.props.subtitle) && (
            <div className="subtitle">{this.props.subtitle}</div>
          )}
        </div>
        {this.props.children}
      </div>
    );
  }
}
