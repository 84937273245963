import React from "react";

import { PropTypes } from "prop-types";
import { Label, FormGroup } from "reactstrap";

import Input from "../../../components/input/Input";
import CreateButtonModal from "../../../components/buttons/createButtonModal/CreateButtonModal";

export default class CreateClinicButton extends React.PureComponent {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired
    };

    state = {
        modalVisible: false,
    };


    onSubmit = formData => {
        let data = { ...formData };
        return this.props.onSubmit(data);
    };


    render() {
        return (
            <CreateButtonModal
                text="Nouvel établissement"
                onSubmit={this.onSubmit}
            >
                <FormGroup className="required">
                    <Label>Finess</Label>
                    <Input type="text" name="id" maxLength={25} required />
                </FormGroup>
                <FormGroup className="required">
                    <Label>Trigramme</Label>
                    <Input type="text" name="shortCode" maxLength={3} required />
                </FormGroup>
                <FormGroup className="required">
                    <Label>Nom</Label>
                    <Input type="text" name="name" maxLength={255} required />
                </FormGroup>
            </CreateButtonModal>


        );
    }
}