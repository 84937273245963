import { PropTypes } from "prop-types";
import { Table, Alert } from "reactstrap";
import JsonViewer from "components/jsonViewer/JsonViewer";
import moment from "moment";
import React from "react";

export default class EventsTab extends React.PureComponent {
  static props = {
    events: PropTypes.array.isRequired,
  };

  render() {
    if (!this.props.events || this.props.events.length === 0) {
      return <Alert color="info">Aucun évènement pour ce dossier</Alert>;
    }

    return (
      <div className="mt-2">
        <Table responsive hover striped>
          <thead>
            <tr>
              <td>Date</td>
              <td>Source</td>
              <td>Event</td>
              <td>Paramètres</td>
            </tr>
          </thead>
          <tbody>
            {this.props.events.map((event) => {
              return (
                <tr key={event.id}>
                  <td>{moment(event.date).format("DD/MM/YYYY HH:mm:ss")}</td>
                  <td>{event.source}</td>
                  <td>{event.event}</td>
                  <td>
                    {event.params ? <JsonViewer json={event.params} /> : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}
