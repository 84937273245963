import ApiClient from "../services/ApiClient";

export default class UsersApi {
  static getAll = () => ApiClient.get("users");

  static add = (data) => ApiClient.post("users", data);

  static edit = (id, data) => ApiClient.patch(`users/${id}`, data);

  static delete = (id) => ApiClient.delete(`users/${id}`);
}
