import ApiClient from "../services/ApiClient";

export default class MedicalStaysApi {
  static getMedicalStay = (healthFacilityId, medicalStayGapId) =>
    ApiClient.get(`medicalStays/${healthFacilityId}/${medicalStayGapId}`);

  static getByAmbulisCareId = (ambulisCareId) =>
    ApiClient.get(`medicalStays/ambulisCareId/${ambulisCareId}`);

  static getEvents = (healthFacilityId, medicalStayGapId) =>
    ApiClient.get(`medicalStaysEvents/${healthFacilityId}/${medicalStayGapId}`);

  static getQoDMessages = (healthFacilityId, medicalStayGapId) =>
    ApiClient.get(
      `medicalStaysQoDMessages/${healthFacilityId}/${medicalStayGapId}`
    );

  static sendActivationEmail = (serialNumber) =>
    ApiClient.get(`medicalStays/${serialNumber}/sendActivationMail`);
}
