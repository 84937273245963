import Authentication from "./Authentication";
import HttpClient from "../services/HttpClient";

export default class ApiClient {
  static refreshingPromise = null;

  static authExpired = null;

  static addAuthExpiredListener = (callback) => {
    ApiClient.authExpired = () => callback();
    return ApiClient.authExpired;
  };

  static removeAuthExpiredListener = (callback) => {
    if (ApiClient.authExpired === callback) {
      ApiClient.authExpired = null;
    }
  };

  static request = async (
    method,
    route,
    body = null,
    multipart = false,
    allowTokenRefresh = true
  ) => {
    let error = null;

    if (ApiClient.refreshingPromise) {
      await ApiClient.refreshingPromise.catch((e) => {
        error = e;
      });
    }

    if (error) {
      return Promise.reject(error);
    } else {
      return HttpClient.request(
        method,
        `${process.env.REACT_APP_API_ENDPOINT}/${route}`,
        Authentication.token,
        body,
        multipart
      ).catch(async (e) => {
        if (e.status === 401 && allowTokenRefresh) {
          ApiClient.refreshToken().catch(() => {});
          return ApiClient.request(method, route, body, multipart, false);
        } else {
          return Promise.reject(e);
        }
      });
    }
  };

  static refreshToken = async () => {
    ApiClient.refreshingPromise = new Promise(async (resolve, reject) => {
      let newAuth = await ApiClient.request(
        "GET",
        `auth/refresh`,
        null,
        false,
        true
      ).catch((e) => {
        if (ApiClient.authExpired) {
          ApiClient.authExpired();
        }

        reject();
      });

      if (newAuth) {
        Authentication.login(newAuth.token);
        resolve();
      }

      ApiClient.refreshingPromise = null;
    });

    return ApiClient.refreshingPromise;
  };

  static get = async (route, allowTokenRefresh = true) =>
    ApiClient.request("GET", route, null, false, allowTokenRefresh);
  static post = async (
    route,
    body = null,
    multipart = false,
    allowTokenRefresh = true
  ) => ApiClient.request("POST", route, body, multipart, allowTokenRefresh);
  static put = async (
    route,
    body = null,
    multipart = false,
    allowTokenRefresh = true
  ) => ApiClient.request("PUT", route, body, multipart, allowTokenRefresh);
  static patch = async (
    route,
    body = null,
    multipart = false,
    allowTokenRefresh = true
  ) => ApiClient.request("PATCH", route, body, multipart, allowTokenRefresh);
  static delete = async (route, allowTokenRefresh = true) =>
    ApiClient.request("DELETE", route, null, false, allowTokenRefresh);
}
