import { CustomInput } from "reactstrap";
import { withFormsy } from "formsy-react";
import PropTypes from "prop-types";
import React from "react";

import ValidationErrors from "../../services/ValidationErrors";

class Checkbox extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    targetValue: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
    inline: PropTypes.bool,
    checked: PropTypes.bool,
    id: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    checked: false,
    inline: false,
    disabled: false,
    targetValue: "1",
  };

  state = {
    checked: this.props.checked,
  };

  constructor(props) {
    super(props);
    const value = this.state.checked ? this.props.targetValue : "";
    this.props.setValue(value);
  }

  onChange = (event) => {
    const value = event.target.checked ? this.props.targetValue : "";

    if (this.props.onChange) {
      this.props.onChange({
        name: this.props.name,
        value: value,
      });
    }

    this.setState(
      {
        checked: event.target.checked,
      },
      () => {
        this.props.setValue(value);
      }
    );
  };

  render() {
    const errorMessage = this.props.errorMessage;
    const className = this.props.isValid ? "valid" : "invalid";

    return (
      <div
        className={`${className} ${
          this.props.inline ? "d-inline-block" : "d-block"
        }`}
      >
        <CustomInput
          type="checkbox"
          id={this.props.id}
          label={this.props.label}
          onChange={this.onChange}
          value={this.props.value}
          inline={this.props.inline}
          checked={this.state.checked}
          invalid={!this.props.isPristine && !this.props.isValid}
          disabled={this.props.disabled}
        />

        {!this.props.isPristine &&
        !this.props.inline &&
        this.props.showRequired ? (
          <div className="invalid-feedback">{ValidationErrors.required}</div>
        ) : (
          <div className="invalid-feedback">{errorMessage}</div>
        )}
      </div>
    );
  }
}

export default withFormsy(Checkbox);
