import ApiClient from "../services/ApiClient";

export default class AuthApi {
  static login(email, password) {
    return ApiClient.post(
      "login",
      {
        email: email,
        password: password,
      },
      false,
      false
    );
  }
}
