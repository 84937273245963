import { BrowserRouter, Switch } from "react-router-dom";
import React from "react";

import Routes from "./Routes";
import ErrorsLayout from "../layouts/errorsLayout/ErrorsLayout";
import PublicLayout from "../layouts/publicLayout/PublicLayout";
import PrivateLayout from "../layouts/privateLayout/PrivateLayout";

export default class Router extends React.PureComponent {

  render() {
    return (
      <BrowserRouter>
        <Switch>
          {Routes.private.map((route) => (
            <PrivateLayout
              {...{ ...route, path: `${route.path}` }}
            />
          ))}

          {Routes.public.map((route) => (
            <PublicLayout {...{ ...route, path: `${route.path}` }} />
          ))}

          {Routes.errors.map((route) => (
            <ErrorsLayout {...route} />
          ))}
        </Switch>
      </BrowserRouter>
    );
  }
}
