import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Route } from "react-router-dom";
import { withRouter, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";

import { withLayoutContextProvider } from "../../services/LayoutContext";
import DisconnectButton from "./disconnectButton/DisconnectButton";
import ScrollPositionManager from "../../components/ScrollPositionManager/ScrollPositionManager";
import SideMenu from "./sideMenu/SideMenu";
import ApiClient from "../../services/ApiClient";
import Authentication from "../../services/Authentication";

import "./PrivateLayout.scss";

function viewport() {
  var e = window,
    a = "inner";
  if (!("innerWidth" in window)) {
    a = "client";
    e = document.documentElement || document.body;
  }
  return { width: e[a + "Width"], height: e[a + "Height"] };
}

class PrivateLayout extends React.PureComponent {
  static props = {
    authentication: PropTypes.shape({
      logout: PropTypes.func.isRequired,
      isLogged: PropTypes.func.isRequired,
      user: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
      }),
    }).isRequired,
    apiClient: PropTypes.shape({
      addAuthExpiredListener: PropTypes.func.isRequired,
      removeAuthExpiredListener: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    sidemenuOpened: viewport().width >= 768,
  };

  adminMenuItems = [
    {
      menuKey: "clinics",
      label: "Etablissements",
      icon: "hospital",
      path: "/clinics",
    },
    {
      menuKey: "users",
      label: "Utilisateurs",
      icon: "users",
      path: "/users",
    },
  ];

  constructor(props) {
    super(props);

    if (!Authentication.isLogged()) {
      this.props.history.push(`/login`);
    }

    this.authExpired = ApiClient.addAuthExpiredListener(() => {
      this.logout();
    });
  }

  componentWillUnmount() {
    ApiClient.removeAuthExpiredListener(this.authExpired);
  }

  logout = () => {
    Authentication.logout();
    this.props.history.push(`/login`);
  };

  toggleSideMenu = () => {
    this.setState((prevState) => {
      return { sidemenuOpened: !prevState.sidemenuOpened };
    });
  };

  render() {
    if (!Authentication.isLogged()) {
      return null;
    }

    if (
      this.props.authorizedIf &&
      typeof this.props.authorizedIf === "function" &&
      !this.props.authorizedIf(Authentication.user)
    ) {
      return <Redirect to={`/404`} />;
    }

    const { component, ...routeProps } = this.props;

    const RouteComponent = component;

    let menuItems = [
      {
        menuKey: "medicalStay",
        label: "Détails dossier",
        icon: "folder",
        path: "/",
      },
      {
        menuKey: "dataAccess",
        label: "Accès données",
        icon: "history",
        path: "/dataAccess",
      },
      {
        menuKey: "gapId",
        label: "Identifiant GAP",
        icon: "search",
        path: "/findGapId",
      }
    ];

    if (Authentication.user.isSuperAdmin) {
      menuItems.push({
        menuKey: "administration",
        label: "Administration",
        subItems: this.adminMenuItems,
      });
    }

    return (
      <div
        className={`privateLayoutContent ${
          this.state.sidemenuOpened ? "sidemenuopened" : "sidemenuclosed"
        }`}
      >
        <div className="header px-2">
          <Button onClick={this.toggleSideMenu}>
            <FontAwesomeIcon icon="bars" />
          </Button>
          <div className="d-flex align-items-center text-right">
            <div>
              {Authentication.user.name}
              <div className="small d-none d-sm-block">
                <i>{Authentication.user.email}</i>
              </div>
            </div>
            <DisconnectButton onClick={this.logout} />
          </div>
        </div>
        <ScrollPositionManager scrollKey="sideMenu">
          {({ connectScrollTarget, ...props }) => (
            <div ref={connectScrollTarget} className="sidemenu">
              <SideMenu
                items={menuItems}
                title={this.props.sideMenuTitle}
                subtitle={this.props.sideMenuSubitle}
              />
            </div>
          )}
        </ScrollPositionManager>
        <div className="pageContent">
          <h1>{this.props.layoutContextProvider.title}</h1>
          <div className="px-0 px-md-2">
            <Route
              {...routeProps}
              render={(props) =>
                RouteComponent ? (
                  <RouteComponent
                    {...props}
                    layoutContextProvider={this.props.layoutContextProvider}
                  />
                ) : null
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withLayoutContextProvider(PrivateLayout));
