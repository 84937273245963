import React from "react";

import { NotificationManager } from "react-notifications";
import moment from "moment";
import validationErrors from "services/ValidationErrors";

import { Row, Col, FormGroup, Label, Button, Table, Alert } from "reactstrap";
import JsonViewer from "components/jsonViewer/JsonViewer";
import Block from "components/block/Block";
import ValidationForm from "components/validationForm/ValidationForm";
import Input from "components/input/Input";

import DataAccessApi from "apis/DataAccessApi";

export default class DataAccessPage extends React.PureComponent {
  state = {
    data: null,
    isSearched: false,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: "Historique des accès",
      activeMenuKey: "dataAccess",
    });
  }

  onSubmit = (param) => {
    this.setState({ isSearched: false, data: null }, async () => {
      this.props.layoutContextProvider.showLoader();
      let nextState = {};

      let list = await DataAccessApi.getUserDataAccess(param.userId).catch(
        (e) => {
          return null;
        }
      );

      if (list) {
        nextState.data = list;
        nextState.isSearched = true;
      } else {
        NotificationManager.error("Une erreur est survenue");
      }

      this.props.layoutContextProvider.hideLoader();

      this.setState(nextState);
    });
  };

  render() {
    return (
      <>
        <Block title="Filtres">
          <ValidationForm onSubmit={this.onSubmit} onChange={this.onFormChange}>
            <FormGroup className="required">
              <Row>
                <Col className="required">
                  <Label>Identifiant utilisateur</Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    id="userId"
                    name="userId"
                    className="form-control"
                    validations="isNumeric"
                    validationError={validationErrors.isNumeric}
                    required
                  />
                </Col>
                <Col>
                  <FormGroup>
                    <Button color="primary" type="submit">
                      Valider
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
          </ValidationForm>
        </Block>

        {this.state.isSearched && this.renderContent()}
      </>
    );
  }

  renderContent = () => {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>accès</strong>
          </span>
        }
      >
        {this.state.data.length === 0 ? (
          <Alert color="primary" className="clearfix">
            Aucun élément trouvé
          </Alert>
        ) : (
          <Table responsive hover striped>
            <thead>
              <tr>
                <th className="width-auto">Date</th>
                <th className="width-auto">Source</th>
                <th className="width-auto">Évènement</th>
                <th className="width-auto">Paramètres source</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.map((d) => {
                return (
                  <tr key={d.id}>
                    <td>{moment(d.date).format("DD/MM/YYYY")}</td>
                    <td>{d.source}</td>
                    <td>{d.event}</td>
                    <td>
                      {d.sourceParams && <JsonViewer json={d.sourceParams} />}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </Block>
    );
  };
}
