import React from "react";

import _ from "lodash";
import { NotificationManager } from "react-notifications";
import classnames from "classnames";

import {
  Row,
  Col,
  FormGroup,
  Label,
  Button,
  Alert,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Block from "../../components/block/Block";
import Select from "../../components/select/Select";
import ValidationForm from "../../components/validationForm/ValidationForm";
import Input from "../../components/input/Input";
import PageDataLoader from "../../components/pageDataLoader/PageDataLoader";
import EventsTab from "./EventsTab/EventsTab";
import QodTab from "./QodTab/QodTab";
import MedicalStaySummary from "./MedicalStaySummary/MedicalStaySummary";

import ClinicsApi from "../../apis/ClinicsApi";
import MedicalStaysApi from "../../apis/MedicalStaysApi";
import QodApi from "apis/QodApi";

export default class MedicalStayPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: null,
    clinics: [],
    medicalStay: null,
    events: null,
    messages: null,
    isSearched: false,
    errorsDescriptions: null,
    activeTab: window.location.hash ? window.location.hash.substr(1) : "events",
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: "Détails d'un dossier",
      activeMenuKey: "medicalStay",
    });

    this.loadData();
  }

  loadData = () => {
    this.setState({ isLoading: true, loadingError: null }, async () => {
      let nextState = { isLoading: false };

      let clinics = await ClinicsApi.getAll().catch((e) => {
        nextState.loadingError = e;
        return null;
      });

      if (clinics) {
        nextState.clinics = _.orderBy(clinics, "name");
      }
      this.setState(nextState);
    });
  };

  onSubmit = (formData) => {
    this.setState({ isSearched: false }, async () => {
      this.props.layoutContextProvider.showLoader();

      let nextState = {
        medicalStay: null,
        events: null,
        messages: null,
        errorsDescriptions: null,
      };

      let traceabilityError = null;
      let qodError = null;

      let data = await Promise.all([
        MedicalStaysApi.getMedicalStay(
          formData.healthFacilityId,
          formData.medicalStayGapId
        ),
        MedicalStaysApi.getEvents(
          formData.healthFacilityId,
          formData.medicalStayGapId
        ),
      ]).catch((e) => {
        if (e.status !== 404) {
          traceabilityError = e;
        }

        return null;
      });

      if (data) {
        nextState.medicalStay = data[0];
        nextState.events = _.orderBy(data[1], "date", "desc");
      }

      if (!traceabilityError) {
        data = await Promise.all([
          MedicalStaysApi.getQoDMessages(
            formData.healthFacilityId,
            formData.medicalStayGapId
          ),
          QodApi.getErrorsDescriptions(),
        ]).catch((e) => {
          if (e.status !== 404) {
            qodError = e;
          }

          return null;
        });

        if (data) {
          nextState.messages = _.orderBy(data[0], "date", "desc");
          nextState.errorsDescriptions = data[1];
        }
      }

      if (!traceabilityError && !qodError) {
        nextState.isSearched = true;
      } else {
        nextState.isSearched = false;
        NotificationManager.error("Une erreur est survenue");
      }

      this.props.layoutContextProvider.hideLoader();
      this.setState(nextState);
    });
  };

  goToEventsTab = () => {
    this.setState({ activeTab: "events" });
    this.props.history.replace("#events");
  };

  goToQodTab = () => {
    this.setState({ activeTab: "qod" });
    this.props.history.replace("#qod");
  };

  render() {
    let clinicsOptions = this.state.clinics.map((c) => ({
      label: `${c.shortCode} - ${c.name}`,
      value: c.id,
    }));
    return (
      <>
        <Block title="Filtres">
          <PageDataLoader
            isLoading={this.state.isLoading}
            loadingText="Chargement des établissements"
            loadingError={this.state.loadingError}
          >
            <ValidationForm
              onSubmit={this.onSubmit}
              onChange={this.onFormChange}
            >
              <FormGroup className="required">
                <Row>
                  <Col className="required col-4">
                    <Label>Etablissement</Label>
                  </Col>
                  <Col className="required col-4">
                    <Label>N° dossier</Label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Select
                      id="healthFacilityId"
                      name="healthFacilityId"
                      placeholder="Choisir"
                      searchable
                      options={clinicsOptions}
                      required
                    />
                  </Col>
                  <Col>
                    <Input
                      id="medicalStayGapId"
                      name="medicalStayGapId"
                      className="form-control"
                      required
                    />
                  </Col>
                  <Col>
                    <FormGroup>
                      <Button color="primary" type="submit">
                        Valider
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </FormGroup>
            </ValidationForm>
          </PageDataLoader>
        </Block>

        {this.state.isSearched && (
          <>
            {!this.state.medicalStay && (
              <Alert color="danger">Ce dossier n'existe pas dans ADEL</Alert>
            )}
            {this.state.medicalStay && (
              <MedicalStaySummary medicalStay={this.state.medicalStay} />
            )}

            {this.renderTabs()}
          </>
        )}
      </>
    );
  }

  renderTabs = () => (
    <Block irreducible>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({
              active: this.state.activeTab === "events",
            })}
            onClick={this.goToEventsTab}
          >
            Évènements
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: this.state.activeTab === "qod",
            })}
            onClick={this.goToQodTab}
          >
            QoD
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={this.state.activeTab}>
        <TabPane tabId="events">
          <EventsTab events={this.state.events} />
        </TabPane>
        <TabPane tabId="qod">
          <QodTab
            errorsDescriptions={this.state.errorsDescriptions}
            messages={this.state.messages}
          />
        </TabPane>
      </TabContent>
    </Block>
  );
}
