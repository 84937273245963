import React from "react";
import { PropTypes } from "prop-types";
import LoadingError from "../loadingError/LoadingError";
import InlineLoader from "../inlineLoader/InlineLoader";
import Block from "../block/Block";

export default class PageDataLoader extends React.PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    loadingError: PropTypes.any,
    loadingText: PropTypes.string,
    children: PropTypes.node,
    useBlock: PropTypes.bool
  };

  static defaultProps = {
    loadingText: "Chargement",
    useBlock: false
  };

  renderLoading() {
    if (this.props.useBlock) {
      return (
        <Block irreducible>
          <InlineLoader block xl text={this.props.loadingText} />
        </Block>
      );
    } else {
      return <InlineLoader block xl text={this.props.loadingText} />;
    }
  }

  render() {
    return this.props.isLoading ? (
      this.renderLoading()
    ) : this.props.loadingError ? (
      <LoadingError error={this.props.loadingError} onRetry={this.props.loadData} />
    ) : this.props.children ? (
      this.props.children
    ) : null;
  }
}
