import React, { Component } from "react";

import LayoutContext from "services/LayoutContext";
import Loader from "components/loader/Loader";
import Router from "routing/Router";

import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";

class App extends Component {
  render() {
    return (
      <LayoutContext>
        <Router />
        <Loader />
        <NotificationContainer />
      </LayoutContext>
    );
  }
}

export default App;
