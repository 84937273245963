import { FormGroup } from "reactstrap";
import { Label } from "reactstrap";
import { PropTypes } from "prop-types";
import React from "react";

import Input from "../../../components/input/Input";
import Checkbox from "../../../components/checkbox/Checkbox";

export default class EditUserForm extends React.PureComponent {
  static propTypes = {
    user: PropTypes.shape({}),
  };

  state = {
    addIsSuperAdmin: this.props.user.isSuperAdmin,
  };

  addIsSuperAdminChange = () => {
    this.setState((prevState) => ({
      addIsSuperAdmin: !prevState.addIsSuperAdmin,
    }));
  };

  render() {
    return (
      <div>
        <FormGroup className="required">
          <Label>Nom</Label>
          <Input
            type="text"
            name="name"
            maxLength={255}
            value={this.props.user.name}
            required
          />
        </FormGroup>
        <FormGroup className="required">
          <Label>Email</Label>
          <Input
            type="text"
            name="email"
            maxLength={255}
            value={this.props.user.email}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label>Password</Label>
          <Input
            type="password"
            name="password"
            helpText="Laisser vide pour ne pas modifier le mot de passe"
          />
        </FormGroup>
        <FormGroup>
          <Label>Administrateur</Label>
          <div>
            <Checkbox
              id="isSuperAdmin"
              name="isSuperAdmin"
              value={this.state.isSuperAdmin}
              onChange={this.addIsSuperAdminChange}
              checked={this.state.addIsSuperAdmin}
            />
          </div>
        </FormGroup>
      </div>
    );
  }
}
