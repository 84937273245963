import React from "react";

import { PropTypes } from "prop-types";
import { Label, FormGroup } from "reactstrap";

import Input from "../../../components/input/Input";

export default class UpdateClinicButton extends React.PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      id: PropTypes.string.isRequired,
      shortCode: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  };

  render() {
    return (
      <div>
        <FormGroup>
          <Label>Finess</Label>
          <div className="pl-3">
            <i>{this.props.data.id}</i>
          </div>
        </FormGroup>
        <FormGroup>
          <Label>Trigramme</Label>
          <div className="pl-3">
            <i>{this.props.data.shortCode}</i>
          </div>
        </FormGroup>
        <FormGroup>
          <Label>Nom</Label>
          <Input
            className="form-control"
            name="name"
            value={this.props.data.name}
          />
        </FormGroup>
      </div>
    );
  }
}
