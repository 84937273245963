import React from "react";
import { withLayoutContextProvider } from "../../services/LayoutContext";
import "./Loader.scss";
import InlineLoader from "../inlineLoader/InlineLoader";

export class Loader extends React.PureComponent {
  render() {
    return this.props.layoutContextProvider.loaderVisible ? (
      <div className="loader d-flex">
        <div className="content p-4 d-flex align-items-center align-self-center justify-content-center">
          <div>
            <InlineLoader block xl />
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default withLayoutContextProvider(Loader);
