import Block from "components/block/Block";
import ValidationForm from "components/validationForm/ValidationForm";
import React from "react";
import { Row, Col, FormGroup, Label, Button, Table, Alert } from "reactstrap";
import Input from "components/input/Input";
import validationErrors from "services/ValidationErrors";
import MedicalStaysApi from "apis/MedicalStaysApi";
import { Link } from "react-router-dom";

export default class FindGapIdPage extends React.PureComponent {
  state = {
    medicalStay: null,
    isSearched: false,
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: "Rechercher un dossier GAP",
      activeMenuKey: "gapId",
    });
  }

  onSubmit = (formValues) => {
    this.setState({ isSearched: false, medicalStay: null }, async () => {
      this.props.layoutContextProvider.showLoader();
      let nextState = { isSearched: true };

      const medicalStay = await MedicalStaysApi.getByAmbulisCareId(
        formValues.careId
      ).catch(() => null);

      nextState.medicalStay = medicalStay;

      this.props.layoutContextProvider.hideLoader();
      this.setState(nextState);
    });
  };

  render() {
    return (
      <>
        <Block title="Recherche">
          <ValidationForm onSubmit={this.onSubmit} onChange={this.onFormChange}>
            <FormGroup className="required">
              <Row>
                <Col className="required">
                  <Label>Identifiant Ambulis</Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    id="careId"
                    name="careId"
                    className="form-control"
                    validations="isNumeric"
                    validationError={validationErrors.isNumeric}
                    required
                  />
                </Col>
                <Col>
                  <FormGroup>
                    <Button color="primary" type="submit">
                      Valider
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
          </ValidationForm>
        </Block>
        {this.state.isSearched && this.renderContent()}
      </>
    );
  }

  renderContent = () => {
    const { medicalStay } = this.state;
    return (
      <Block
        irreducible
        title={<span>{medicalStay ? "1" : "0"} dossier trouvé</span>}
      >
        {!medicalStay ? (
          <Alert color="warning" className="clearfix">
            Aucun dossier n'a été trouvé
          </Alert>
        ) : (
          <div>
            Numéro de dossier GAP :{" "}
            <strong>{medicalStay.medicalStayGapId}</strong>
          </div>
        )}
      </Block>
    );
  };
}
