import { PropTypes } from "prop-types";
import React from "react";
import Input from "../../../components/input/Input";
import Checkbox from "../../../components/checkbox/Checkbox";
import { Label, FormGroup } from "reactstrap";
import CreateButtonModal from "../../../components/buttons/createButtonModal/CreateButtonModal";

export default class CreateUserButton extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    modalVisible: false,
    addIsSuperAdmin: false,
  };

  addIsSuperAdminChange = () => {
    this.setState((prevState) => ({
      addIsSuperAdmin: !prevState.addIsSuperAdmin,
    }));
  };

  onSubmit = (formData) => {
    let data = { ...formData };

    return this.props.onSubmit(data);
  };

  render() {
    return (
      <CreateButtonModal
        text="Nouvel utilisateur"
        icon="plus-circle"
        onSubmit={this.onSubmit}
      >
        <FormGroup className="required">
          <Label>Nom</Label>
          <Input type="text" name="name" maxLength={255} required />
        </FormGroup>

        <FormGroup className="required">
          <Label>Email</Label>
          <Input type="text" name="email" maxLength={255} required />
        </FormGroup>

        <FormGroup className="required">
          <Label>Mot de passe</Label>
          <Input type="password" name="password" required />
        </FormGroup>
        <FormGroup>
          <Label>Administrateur</Label>
          <div>
            <Checkbox
              id="isSuperAdmin"
              name="isSuperAdmin"
              onChange={this.addIsSuperAdminChange}
              checked={this.state.addIsSuperAdmin}
            />
          </div>
        </FormGroup>
      </CreateButtonModal>
    );
  }
}
