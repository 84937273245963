import React from "react";
import { Table, Badge } from "reactstrap";

import Authentication from "services/Authentication";

import Block from "../../components/block/Block";
import PageDataLoader from "../../components/pageDataLoader/PageDataLoader";
import EditButtonModal from "../../components/buttons/editButtonModal/EditButtonModal";
import DeleteButton from "../../components/buttons/deleteButton/DeleteButton";
import CreateUserButton from "./createUserButton/CreateUserButton";
import EditUserForm from "./editUserForm/EditUserForm";
import { NotificationManager } from "react-notifications";

import UsersApi from "apis/UsersApi";

export default class UsersPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: null,
    users: [],
  };

  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: "Gestion des utilisateurs",
      activeMenuKey: "users",
    });

    this.loadData();
  }

  loadData = () => {
    this.setState({ isLoading: true, loadingError: null }, async () => {
      let nextState = { isLoading: false };

      let users = await UsersApi.getAll().catch((e) => {
        nextState.loadingError = e;
        return null;
      });

      if (users) {
        nextState.users = users;
      }
      this.setState(nextState);
    });
  };

  onCreateUser = async (formData) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;

    await UsersApi.add(formData).catch((e) => {
      NotificationManager.error(e.message, "Une erreur est survenue");
      success = false;
    });
    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success("Utilisateur crée avec succès", null);
      this.loadData();
    }
  };

  onEdit = async (oldData, formData) => {
    this.props.layoutContextProvider.showLoader();

    let success = true;

    await UsersApi.edit(oldData.id, formData).catch((e) => {
      NotificationManager.error(e.message, "Une erreur est survenue");
      success = false;
    });

    this.props.layoutContextProvider.hideLoader();

    if (success) {
      NotificationManager.success("Utilisateur modifié", null);
      this.loadData();
    }
  };

  onDelete = async (user) => {
    this.props.layoutContextProvider.showLoader();
    let success = true;
    await UsersApi.delete(user.id).catch((e) => {
      success = false;
      NotificationManager.error("Une erreur est survenue", null);
    });

    if (success) {
      NotificationManager.success("Utilisateur supprimé", null);
      this.loadData();
    }

    this.props.layoutContextProvider.hideLoader();
  };

  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>utilisateurs</strong>
          </span>
        }
      >
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingText="Chargement des utilisateurs"
          loadingError={this.state.loadingError}
        >
          <div className="mt-2">
            <div className="text-right mb-2">
              <CreateUserButton onSubmit={this.onCreateUser} />
            </div>
            <Table responsive hover striped>
              <thead>
                <tr>
                  <td>Nom</td>
                  <td>Email</td>
                  <td>Administrateur</td>
                  <td className="width-auto text-center">Actions</td>
                </tr>
              </thead>
              <tbody>
                {this.state.users.map((user) => {
                  return (
                    <tr>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>
                        <Badge color={user.isSuperAdmin ? "success" : "danger"}>
                          {user.isSuperAdmin ? "Oui" : "Non"}
                        </Badge>
                      </td>
                      <td className="width-auto text-center">
                        <EditButtonModal
                          data={user}
                          onSubmit={this.onEdit}
                          title="Modifier un utilisateur"
                          className="mr-1"
                        >
                          <EditUserForm user={user} />
                        </EditButtonModal>
                        {Authentication.user.id !== user.id && (
                          <DeleteButton
                            data={user}
                            title="Supprimer un utilisateur"
                            onConfirm={this.onDelete}
                            className="ml-1"
                          >
                            Voulez-vous réellement supprimer cette
                            correspondance ?
                          </DeleteButton>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </PageDataLoader>
      </Block>
    );
  }
}
