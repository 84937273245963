const ValidationErrors = {
  required: "Ce champ est obligatoire",
  isEMail: "Format d'email invalide",
  isInt: "Veuillez fournir un nombre entier valide",
  isNumeric: "Veuillez fournir un nombre valide",
  isUrl: "Veuillez fournir une adresse URL valide",
  isLength: length => `Le champ doit faire ${length} caractères`,
  maxLength: length => `Veuillez fournir au plus ${length} caractères`
};

export default ValidationErrors;
