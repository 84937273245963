import { PropTypes } from "prop-types";
import { Row, Col, FormGroup, Label, Badge } from "reactstrap";
import Block from "components/block/Block";
import moment from "moment";
import React from "react";

import SendActivationEmailButton from "./sendActivationEmailButton/SendActivationEmailButton";

export default class MedicalStaySummary extends React.PureComponent {
  static props = {
    medicalStay: PropTypes.object.isRequired,
  };

  render() {
    const { medicalStay } = this.props;

    return (
      <Block title="Résumé du dossier">
        <Row>
          <Col>
            <FormGroup className="row">
              <Label className="col-sm-4">ID ADEL</Label>
              <div className="col-sm-8">{medicalStay.serialNumber}</div>
            </FormGroup>
          </Col>

          <Col>
            <FormGroup className="row">
              <Label className="col-sm-4">ID Ambulis</Label>
              <div className="col-sm-8">{medicalStay.ambulisCareId}</div>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup className="row">
              <Label className="col-sm-4">N° séjour Ambulis</Label>
              <div className="col-sm-8">{medicalStay.ambulisFileNumber}</div>
            </FormGroup>
          </Col>

          <Col>
            <FormGroup className="row">
              <Label className="col-sm-4">ID Protocole</Label>
              <div className="col-sm-8">
                {medicalStay.ambulisOperatingMethodId}
              </div>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup className="row">
              <Label className="col-sm-4">Version protocole Ambulis</Label>
              <div className="col-sm-8">
                {medicalStay.ambulisOperatingMethodVersion}
              </div>
            </FormGroup>
          </Col>

          <Col>
            <FormGroup className="row">
              <Label className="col-sm-4">Code MDT</Label>
              <div className="col-sm-8">{medicalStay.mdtCode}</div>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup className="row">
              <Label className="col-sm-4">Identifiant GAP Patient</Label>
              <div className="col-sm-8">{medicalStay.patientGapId}</div>
            </FormGroup>
          </Col>

          <Col>
            <FormGroup className="row">
              <Label className="col-sm-4">Identifiant GAP Praticien</Label>
              <div className="col-sm-8">{medicalStay.practitionerGapId}</div>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup className="row">
              <Label className="col-sm-4">ID clinique</Label>
              <div className="col-sm-8">{medicalStay.healthFacilityId}</div>
            </FormGroup>
          </Col>

          <Col>
            <FormGroup className="row">
              <Label className="col-sm-4">ID dossier GAP</Label>
              <div className="col-sm-8">{medicalStay.medicalStayGapId}</div>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup className="row">
              <Label className="col-sm-4">Email patient</Label>
              <div className="col-sm-8">{medicalStay.patientEmail}</div>
            </FormGroup>
          </Col>

          <Col>
            <FormGroup className="row">
              <Label className="col-sm-4">Téléphone patient</Label>
              <div className="col-sm-8">{medicalStay.patientMobileNumber}</div>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup className="row">
              <Label className="col-sm-4">Date de convocation</Label>
              <div className="col-sm-8">
                {moment(medicalStay.convocationDate).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
              </div>
            </FormGroup>
          </Col>

          <Col>
            <FormGroup className="row">
              <Label className="col-sm-4">Date d'intervention</Label>
              <div className="col-sm-8">
                {moment(medicalStay.interventionDate).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
              </div>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup className="row">
              <Label className="col-sm-4">Date de sortie</Label>
              <div className="col-sm-8">
                {moment(medicalStay.exitDate).format("DD/MM/YYYY HH:mm:ss")}
              </div>
            </FormGroup>
          </Col>

          <Col>
            <FormGroup className="row">
              <Label className="col-sm-4">Date fin observation</Label>
              <div className="col-sm-8">
                {moment(medicalStay.endObservanceDate).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}{" "}
              </div>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup className="row">
              <Label className="col-sm-4">Actif</Label>
              <div className="col-sm-8">
                <Badge color={medicalStay.active ? "success" : "danger"}>
                  {medicalStay.active ? "Oui" : "Non"}
                </Badge>
              </div>
            </FormGroup>
          </Col>

          <Col>
            <FormGroup className="row">
              <Label className="col-sm-4">Importé</Label>
              <div className="col-sm-8">
                <Badge color={medicalStay.imported ? "success" : "danger"}>
                  {medicalStay.imported ? "Oui" : "Non"}
                </Badge>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <SendActivationEmailButton serialNumber={medicalStay.serialNumber} />
        </div>
      </Block>
    );
  }
}
