import React from "react";
import { PropTypes } from "prop-types";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table, Badge, Alert } from "reactstrap";
import JsonViewer from "components/jsonViewer/JsonViewer";

export default class QodTab extends React.PureComponent {
  static props = {
    messages: PropTypes.array.isRequired,
    errorsDescriptions: PropTypes.array.isRequired,
  };

  getErrorMessage = (errorCode) => {
    let message = this.props.errorsDescriptions.find(
      (m) => m.code === errorCode
    );

    return message ? message.description : errorCode;
  };

  render() {
    if (!this.props.messages || this.props.messages.length === 0) {
      return <Alert color="info">Aucun message pour ce dossier</Alert>;
    }

    return (
      <div className="mt-2">
        <Table responsive hover striped>
          <thead>
            <tr>
              <td>Date et heure</td>
              <td>ID message</td>
              <td>Type</td>
              <td>Règles gestion</td>
              <td>Statut</td>
              <td>Erreurs/alertes</td>
            </tr>
          </thead>
          <tbody>
            {this.props.messages.map((m) => {
              let statutColor = "";
              let statutMessage = "";

              switch (m.status) {
                case "warning":
                  statutColor = "warning";
                  statutMessage = "Alerte";
                  break;
                case "success":
                  statutColor = "success";
                  statutMessage = "Succès";
                  break;
                case "error":
                  statutColor = "danger";
                  statutMessage = "Erreur";
                  break;
                default:
                  statutColor = "dark";
                  statutMessage = "Inconnu";
              }

              return (
                <tr key={m.id}>
                  <td>{moment(m.date).format("DD/MM/YYYY HH:mm:ss")}</td>
                  <td>{m.messageId}</td>
                  <td>{m.type}</td>
                  <td>
                    {m.managementRules ? (
                      <JsonViewer json={m.managementRules} collapsed />
                    ) : null}
                  </td>
                  <td>
                    <Badge color={statutColor}>{statutMessage}</Badge>
                  </td>
                  <td>
                    {m.infos != null && m.infos.length > 0 && (
                      <ul className="p-0 list-unstyled">
                        Infos :
                        {m.infos.map((i) => (
                          <li key={m.id + "_" + i}>
                            <FontAwesomeIcon
                              className="text-info"
                              icon="info-circle"
                            />{" "}
                            {this.getErrorMessage(i)}
                          </li>
                        ))}
                      </ul>
                    )}
                    {m.warnings != null && m.warnings.length > 0 && (
                      <ul className="p-0 list-unstyled">
                        Alertes :
                        {m.warnings.map((w) => (
                          <li key={m.id + "_" + w}>
                            <FontAwesomeIcon
                              className="text-warning"
                              icon="exclamation-triangle"
                            />{" "}
                            {this.getErrorMessage(w)}
                          </li>
                        ))}
                      </ul>
                    )}
                    {m.errors != null && m.errors.length > 0 && (
                      <ul className="p-0 list-unstyled">
                        Erreurs :
                        {m.errors.map((e) => (
                          <li key={m.id + "_" + e}>
                            <FontAwesomeIcon
                              className="text-danger"
                              icon="exclamation-circle"
                            />{" "}
                            {this.getErrorMessage(e)}
                          </li>
                        ))}
                      </ul>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}
