import Error404Page from "pages/error404Page/Error404Page";
import MedicalStayPage from "pages/medicalStayPage/MedicalStayPage";
import LoginPage from "pages/loginPage/LoginPage";
import ClinicsPage from "pages/clinicsPage/ClinicsPage";
import UsersPage from "pages/usersPage/UsersPage";
import DataAccessPage from "pages/dataAccessPage/DataAccessPage";
import FindGapIdPage from "pages/findGapIdPage/FindGapIdPage";

export default class Routes {
  static public = [
    {
      key: "adminLogin",
      path: "/login",
      exact: true,
      component: LoginPage,
    },
  ];

  static private = [
    {
      key: "adminHome",
      path: "/",
      exact: true,
      component: MedicalStayPage,
    },
    {
      key: "dataAccess",
      path: "/dataAccess",
      exact: true,
      component: DataAccessPage,
    },
    {
      key: "gapId",
      path: "/findGapId",
      exact: true,
      component: FindGapIdPage,
    },
    {
      key: "medicalStay",
      path: "/medicalStay",
      exact: true,
      component: MedicalStayPage,
    },
    {
      key: "clinics",
      path: "/clinics",
      exact: true,
      component: ClinicsPage,
    },
    {
      key: "users",
      path: "/users",
      exact: true,
      component: UsersPage,
    },
  ];

  static errors = [{ key: "404", exact: true, component: Error404Page }];
}
