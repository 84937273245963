import { Route } from "react-router-dom";
import React from "react";

import { withLayoutContextProvider } from "../../services/LayoutContext";
import ElsanLogo from "../../components/elsanLogo/ElsanLogo";

import "./ErrorsLayout.scss";

class ErrorsLayout extends React.PureComponent {
  static propTypes = {};

  state = {};

  render() {
    const { component, ...routeProps } = this.props;
    const RouteComponent = component;
    return (
      <div className="errorsMainContainer">
        <div className="logoContainer contentContainer">
          <ElsanLogo />
        </div>
        <div className="routeContainer contentContainer">
          <Route
            {...routeProps}
            render={props =>
              RouteComponent ? (
                <RouteComponent
                  {...props}
                  layoutContextProvider={this.props.layoutContextProvider}
                />
              ) : null
            }
          />
        </div>
      </div>
    );
  }
}

export default withLayoutContextProvider(ErrorsLayout);
