import React from "react";

import { Table, FormGroup } from "reactstrap";
import _ from "lodash";
import { NotificationManager } from 'react-notifications';

import ClinicsApi from "../../apis/ClinicsApi";

import Block from "../../components/block/Block";
import CreateClinicButton from "./createClinic/CreateClinicButton";
import UpdateClinicButton from "./updateClinic/UpdateClinicButton";
import EditButtonModal from "../../components/buttons/editButtonModal/EditButtonModal";
import DeleteButton from "../../components/buttons/deleteButton/DeleteButton";
import PageDataLoader from "../../components/pageDataLoader/PageDataLoader";

export default class ClinicsPage extends React.PureComponent {
  state = {
    isLoading: true,
    loadingError: false,
    clinics: null,
  };



  componentDidMount() {
    this.props.layoutContextProvider.change({
      title: "Gestion des établissements",
      activeMenuKey: "clinics",
    });

    this.loadData();
  };

  loadData = () => {
    this.setState(
      { isLoading: true, loadingError: false, clinics: null },
      async () => {
        let nexState = { isLoading: false };
        nexState.clinics = await ClinicsApi.getAll().catch((e) => {
          nexState.loadingError = e;
          return null;
        });

        if (nexState.clinics) {
          nexState.clinics = _.orderBy(nexState.clinics, "shortName");
        }
        this.setState(nexState);
      }
    );
  };

  onCreate = async formData => {
    this.props.layoutContextProvider.showLoader();
    let error = null;

    await ClinicsApi.add(formData).catch((e) => {
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (!error) {
      NotificationManager.success('Votre établissement a bien été créé');
      this.loadData();
      return Promise.resolve();
    } else {
      return Promise.reject(error);
    }
  }

  onUpdate = async (oldData, formData) => {
    this.props.layoutContextProvider.showLoader();

    let error = null;

    await ClinicsApi.update(oldData.id, formData).catch((e) => {
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (!error) {
      NotificationManager.success('Votre établissement a bien été modifié');
      this.loadData();
      return Promise.resolve();
    } else {
      return Promise.reject(error);
    }
  };

  onDelete = async formData => {
    this.props.layoutContextProvider.showLoader();
    let id = formData.id;
    let error = null;

    await ClinicsApi.delete(id).catch((e) => {
      error = e;
    });

    this.props.layoutContextProvider.hideLoader();

    if (!error) {
      NotificationManager.success('Votre établissement a bien été supprimé');
      this.loadData();
      return Promise.resolve();
    } else {
      return Promise.reject(error);
    }
  };


  render() {
    return (
      <Block
        irreducible
        title={
          <span>
            Liste des <strong>établissements</strong>
          </span>
        }
      >
        <PageDataLoader
          isLoading={this.state.isLoading}
          loadingError={this.state.loadingError}
          loadingText="Chargement des cliniques"
        >
          {this.renderContent()}
        </PageDataLoader>
      </Block>
    );
  }

  renderContent = () => {
    if (!this.state.clinics) return null;

    return (
      <div className="ClinicsPage">
        <FormGroup className="text-right">
          <CreateClinicButton
            onSubmit={this.onCreate}
          />
        </FormGroup>

        <Table responsive hover striped>
          <thead>
            <tr>
              <th className="width-auto">Trigramme</th>
              <th className="width-auto">Finess</th>
              <th>Nom</th>
              <th className="text-center width-auto">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.clinics.map((clinic) => {
              return (
                <tr key={clinic.shortCode}>
                  <td className="text-center">{clinic.shortCode}</td>
                  <td>{clinic.id}</td>
                  <td>{clinic.name}</td>
                  <td className="width-auto text-center">
                    <span className='mx-2'>
                      <EditButtonModal
                        data={clinic}
                        text="Modifier un établissement"
                        onSubmit={this.onUpdate}
                        className="mr-1"
                      >
                        <UpdateClinicButton
                          data={clinic}
                        />
                      </EditButtonModal>

                    </span>
                    <DeleteButton
                      data={clinic}
                      title='Supprimer un établissment'
                      onConfirm={this.onDelete}
                    >
                      Voulez-vous réellement supprimer cet établissemnt{' '}
                      <i>"{clinic.name}"</i> ?
                    </DeleteButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}
