import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { NotificationManager } from "react-notifications";
import { PropTypes } from "prop-types";
import { withLayoutContextProvider } from "services/LayoutContext";
import MedicalStaysApi from "apis/MedicalStaysApi";
import React from "react";

class SendActivationEmailButton extends React.PureComponent {
  static propTypes = {
    serialNumber: PropTypes.string.isRequired,
  };

  state = {
    modalOpened: false,
  };

  toggleModal = () =>
    this.setState((prevState) => {
      return { modalOpened: !prevState.modalOpened };
    });

  sendActivationEmail = async () => {
    this.props.layoutContextProvider.showLoader();

    let error = null;
    await MedicalStaysApi.sendActivationEmail(this.props.serialNumber).catch(
      (e) => {
        error = e;
      }
    );

    if (error) {
      switch (error.status) {
        case 404:
          NotificationManager.error(
            "L'email n'a pas pu être envoyé : dossier introuvable, désactivé ou ne pouvant être importé dans ADEL."
          );

          break;
        default:
          NotificationManager.error("Une erreur est survenue.");
          break;
      }
    } else {
      this.toggleModal();
      NotificationManager.success("L'email d'activation a été renvoyé.");
    }

    this.props.layoutContextProvider.hideLoader();
  };

  render() {
    return (
      <>
        <Button color="primary" onClick={this.toggleModal}>
          Renvoyer l'email d'activation
        </Button>

        <Modal isOpen={this.state.modalOpened} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>
            Email d'activation
          </ModalHeader>
          <ModalBody>Voulez-vous renvoyer un email d'activation ?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleModal} outline>
              Annuler
            </Button>
            <Button color="primary" onClick={this.sendActivationEmail}>
              Envoyer
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default withLayoutContextProvider(SendActivationEmailButton);
