import React from "react";
import PropTypes from "prop-types";

import ReactJson from "react-json-view";

export default class JsonViewer extends React.Component {
  static propTypes = {
    json: PropTypes.string.isRequired,
    collapsed: PropTypes.bool,
  };

  static defaultProps = {
    collapsed: false,
  };

  render() {
    return (
      <ReactJson
        src={this.props.json}
        name={null}
        collapsed={this.props.collapsed}
        displayDataTypes={false}
        displayObjectSize={false}
        enableClipboard={false}
        displayArrayKey={false}
      />
    );
  }
}
