import React from "react";
import { PropTypes } from "prop-types";
import StringHelper from "../helpers/StringHelper";

const Context = React.createContext();

export const LayoutContextProviderShape = PropTypes.shape({
  title: PropTypes.string,
  activeMenuKey: PropTypes.string,
  loaderVisible: PropTypes.bool,
  change: PropTypes.func,
  showLoader: PropTypes.func,
  hideLoader: PropTypes.func
});

export default class LayoutContext extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    initialTitle: PropTypes.string,
    initialCounter: PropTypes.number
  };

  static defaultProps = {
    initialTitle: "",
    initialCounter: 0,

    title: null,
    activeMenuKey: ""
  };

  loaderCounter = this.props.initialCounter;

  state = {
    title: StringHelper.isNullOrEmpty(this.props.initialTitle)
      ? this.props.title
      : this.props.initialTitle,
    activeMenuKey: this.props.activeMenuKey,
    ...this.getInvariableStateParts()
  };

  constructor(props) {
    super(props);

    this.notificationDOMRef = React.createRef();
  }

  getInvariableStateParts() {
    return {
      loaderVisible: this.loaderCounter > 0,
      change: layoutState => this.change(layoutState),
      showLoader: () => this.showLoader(),
      hideLoader: () => this.hideLoader()
    };
  }

  change = layoutState => {
    this.setState({
      ...layoutState,
      ...this.getInvariableStateParts()
    });
  };

  showLoader = () => {
    this.loaderCounter = Math.max(0, this.loaderCounter + 1);
    this.change({});
  };

  hideLoader = () => {
    this.loaderCounter = Math.min(0, this.loaderCounter - 1);
    this.change({});
  };

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export function withLayoutContextProvider(Component) {
  return class LayoutProviderComponent extends React.PureComponent {
    render() {
      return (
        <Context.Consumer>
          {context => (
            <Component {...this.props} layoutContextProvider={context} />
          )}
        </Context.Consumer>
      );
    }
  };
}
